<template>
  <div class="bonus-balance" :class="{ mobile }" data-t="bonus-balance-pn5i">
    <div v-if="!noTitle" class="title">{{ t('bonuses.bonusAccount') }}</div>
    <div class="amount">{{ amount }}</div>
    <StIcon class="icon" name="lock-solid" :size="12" />
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

defineProps<{
  amount: string
  mobile?: boolean
  noTitle?: boolean
}>()
</script>

<style scoped>
.bonus-balance {
  display: inline-flex;
  align-items: center;

  .title {
    margin-right: var(--spacing-050);
    font: var(--desktop-text-xs-medium);
    color: var(--text-tertiary);
  }

  .amount {
    margin-right: var(--spacing-025);
    font: var(--desktop-text-xs-medium);
    color: var(--text-warning);
  }

  .icon {
    background-color: var(--text-warning);
  }
}

.mobile {
  .title {
    font: var(--mobile-caption-1-regular);
  }

  .amount {
    font: var(--mobile-caption-1-regular);
  }
}
</style>
